<template>
  <S2SForm :title="`Create an Order for ${customer}`">
    <v-layout
      v-if="cart.items && cart.items.length > 0"
      wrap
      slot="toolbar-content"
      justify-end
    >
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            color="accent"
            v-on="on"
            @click="cartDialog = true"
            :loading="loading"
          >
            <v-icon>mdi-cart</v-icon> View Cart
          </v-btn>
          <v-badge
            class="accent lighten-2"
            :content="cart.items.length"
            offset-x="10"
            offset-y="10"
          ></v-badge>
        </template>
        <span>View Cart</span>
      </v-tooltip>
    </v-layout>

    <v-container fluid>
      <v-row justify="end">
        <v-col cols="12" class="text-right">
          <div class="d-inline-flex flex-row">
            <v-text-field
              v-model="searchVal"
              label="Search"
              class="mr-3"
              @input="searchProduct()"
              clearable
              append-icon="search"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <v-card>
            <v-navigation-drawer permanent width="100%">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title">
                    Categories
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list dense nav>
                <v-list-item
                  v-for="item in productCategories"
                  :key="item.name"
                  link
                  :class="categorySelect.id === item.id ? 'active' : ''"
                >
                  <v-list-item-content
                    v-model="categorySelect"
                    @click="filterByCategory(item)"
                  >
                    <v-list-item-title>
                      {{
                        item.name.charAt(0).toUpperCase() + item.name.slice(1)
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-card>
        </v-col>
        <v-col cols="12" sm="9">
          <v-row v-if="pagerSize > 1">
            <v-col cols="12" class="pt-0">
              <ul class="pagination pagination-sm pl-0">
                <li
                  v-for="(page, index) in pagerSize"
                  :key="index"
                  class="page-item"
                  :class="page === activePageNum ? 'active' : ''"
                >
                  <a class="page-link" @click="loadMore(page)">{{ page }}</a>
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row dense v-if="!reload">
            <v-col v-if="!products.length">No products found!</v-col>
            <v-col v-for="product in products" :key="product.name" cols="4">
              <v-card hover @click="addToCartDialog(product)" tile>
                <div class="product-preview">
                  <div class="header">
                    <div class="product-title">{{ product.name }}</div>
                    <div class="sub-title"></div>
                  </div>
                  <div class="image">
                    <div
                      class="tags text-center"
                      v-if="checkLabels(product) != ''"
                    >
                      {{ checkLabels(product) }}
                    </div>
                    <v-img
                      v-if="product.images.length"
                      :src="product.images[0].name"
                      class="align-end"
                      height="200px"
                      contain
                    >
                      <v-flex
                        v-if="!product.available"
                        class="lightbox float-right"
                      >
                        <v-chip class="ma-2" color="red" text-color="white">
                          <strong class="subheading">Out of Stock</strong>
                        </v-chip>
                      </v-flex>
                      <v-flex v-if="product.discount" class="float-right">
                        <v-chip class="ma-2" color="green" text-color="white">
                          {{
                            product.discount
                              ? product.discount.percentage + "%"
                              : "null"
                          }}
                        </v-chip>
                      </v-flex>
                    </v-img>
                    <v-img v-else height="200px">
                      <v-flex
                        v-if="!product.available"
                        class="lightbox float-right"
                      >
                        <v-chip class="ma-2" color="red" text-color="white">
                          <strong class="subheading">Out of Stock</strong>
                        </v-chip>
                      </v-flex>
                      <v-flex v-if="product.discount" class="float-right">
                        <v-chip class="ma-2" color="green" text-color="white">
                          {{
                            product.discount
                              ? product.discount.percentage + "%"
                              : "null"
                          }}
                        </v-chip>
                      </v-flex>
                    </v-img>
                  </div>
                  <div class="footer pa-1 text-center">
                    {{ getCheapestOption(product) }}
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" class="text-center">
              <v-progress-circular
                :size="70"
                :width="7"
                indeterminate
              ></v-progress-circular>
              <div class="subheading mt-2">Loading...</div>
            </v-col>
          </v-row>
          <v-row v-if="pagerSize > 1">
            <v-col cols="12" class="pt-0">
              <ul class="pagination pagination-sm pl-0">
                <li
                  v-for="(page, index) in pagerSize"
                  :key="index"
                  class="page-item"
                  :class="page === activePageNum ? 'active' : ''"
                >
                  <a class="page-link">{{ page }}</a>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- DIALOGS -->
    <!-- PRODUCT OPTIONS -->
    <v-dialog v-model="addDialog" width="600" persistent>
      <v-card>
        <v-layout v-if="optionsToBeAdded.length" class="pa-3">
          <v-row>
            <v-col cols="4">
              <v-img
                :src="optionsToBeAdded[0].image"
                height="150px"
                contain
              ></v-img>
            </v-col>
            <v-col>
              <div class="title">
                {{ optionsToBeAdded[0].productName }}
                <span
                  v-if="!optionsToBeAdded[0].available"
                  align="center"
                  class="lightbox error--text pa-2 fill-height"
                >
                  <strong class="subheading">Out of Stock</strong>
                </span>
              </div>
              <div class="display-6">
                {{ optionsToBeAdded[0].productShortDescription }}
              </div>

              <v-chip
                class="mr-1"
                color="accent"
                text-color="white"
                x-small
                pill
                v-if="optionsToBeAdded[0].labels != ''"
              >
                {{ optionsToBeAdded[0].labels.toUpperCase() }}
              </v-chip>
            </v-col>
          </v-row>
        </v-layout>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in optionsToBeAdded" :key="item.name">
                  <td>
                    {{ item.name }} @
                    {{
                      formatOptionCurrency(selectedProduct.price, item.price)
                    }}
                  </td>
                  <td>
                    <v-text-field
                      v-model="item.quantity"
                      @blur="selectProductsToAdd(item)"
                      label="Qty"
                      placeholder="0"
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-layout
          v-if="optionsToBeAdded[0] && optionsToBeAdded[0].productDescription"
          class="pa-3"
        >
          <v-row class="pa-5">
            {{ optionsToBeAdded[0].productDescription }}
          </v-row>
        </v-layout>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="cancelAdding()">Cancel</v-btn>
          <v-btn
            v-if="optionsToBeAdded[0]"
            color="accent"
            @click="addProducts()"
            :disabled="!optionsToBeAdded[0].available"
            >Add To Cart</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- SHOW CART -->
    <v-dialog v-model="cartDialog" width="600" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Cart
        </v-card-title>

        <v-card-text class="mt-3 pa-0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Image</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Qty</th>
                  <th class="text-left">Price</th>
                  <th class="text-left">Remove</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="6" class="text-center">
                    <v-progress-circular
                      indeterminate
                      color="accent"
                    ></v-progress-circular>
                  </td>
                </tr>
                <tr v-else v-for="item in cart.items" :key="item.itemHash">
                  <td class="pt-2 pb-2">
                    <v-img
                      :src="item.detail.images[0].thumbnail"
                      height="50px"
                      width="50px"
                      contain
                    ></v-img>
                  </td>
                  <td>
                    <span v-if="item.options">{{ item.options[0].name }}</span>
                    <span v-else>{{ item.detail.name }}</span>
                  </td>
                  <td>
                    <v-edit-dialog
                      :return-value.sync="item.quantity"
                      @save="updateProduct(item)"
                      persistent
                      large
                      save-text="Update"
                    >
                      {{ item.quantity }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="item.quantity"
                          :rules="[(v) => !!v || 'Product Quantity required']"
                          label="Quantity"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>{{ formatCurrency(item.linePrice) }}</td>
                  <td>
                    <v-btn text small @click="removeItemFromCart(item)"
                      ><v-icon small color="red">mdi-delete</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>

          <v-flex v-if="!loading" class="text-right pa-3">
            <div class="title">
              <strong class="mr-3">Total:</strong>
              <strong>{{ formatCurrency(cart.cartTotal) }}</strong>
            </div>
          </v-flex>
        </v-card-text>

        <v-divider></v-divider>

        <v-flex class="mt-3 pa-5 pt-1">
          <v-row>
            <v-col cols="6" xs="12">
              <label>Fullfilment</label>
              <v-radio-group v-model="checkout['fullfilment']" row>
                <v-radio
                  v-if="activeShop.acceptCollections"
                  label="Pickup"
                  value="SELFPICKUP"
                ></v-radio>
                <v-radio
                  v-if="activeShop.acceptDeliveries"
                  label="Delivery"
                  value="DELIVERY"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6" xs="12">
              <v-select
                v-model="checkout['address']"
                label="Address"
                v-if="checkout['fullfilment'] === 'DELIVERY'"
                :items="orderFor.addresses"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>

          <label>Notes</label>
          <v-textarea v-model="checkout['notes']" outlined></v-textarea>
        </v-flex>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cartDialog = false">Continue Shopping</v-btn>
          <v-btn
            color="accent"
            @click="
              checkoutDialog = true;
              cartDialog = false;
            "
            :disabled="
              checkout['fullfilment'] == null ||
              (checkout['fullfilment'] === 'DELIVERY' &&
                checkout['address'] == null)
            "
            >Checkout</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- REVIEW -->
    <v-dialog ref="checkout" v-model="checkoutDialog" width="600" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Order Summary</v-card-title
        >

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                hide-details
                readonly
                :value="orderFor.name"
                label="Placed For"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                hide-details
                readonly
                label="Fullfilment"
                :value="
                  checkout.fullfilment === 'SELFPICKUP' ? 'Pickup' : 'Delivery'
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="checkout.address">
              <v-text-field
                hide-details
                :value="getAddress(checkout.address)"
                label="Address"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="checkout.notes">
              <v-text-field
                hide-details
                :value="checkout.notes"
                label="Notes"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                flat
                hide-details
                :value="formatCurrency(cart.cartTotal)"
                label="Total"
                readonly
                class="text-right"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              checkoutDialog = false;
              cartDialog = true;
            "
            >Back</v-btn
          >
          <v-btn
            color="accent"
            @click="checkoutCart()"
            :loading="loading"
            ref="button"
            >Place Order</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils/";
  import debounce from "lodash.debounce";

  export default Vue.extend({
    name: "CustomerOrder",

    props: {
      connectionId: {
        type: String,
        default: "0",
      },
    },

    data: function () {
      return {
        searchVal: "",
        addDialog: false,
        cartDialog: false,
        checkoutDialog: false,
        optionsToBeAdded: [],
        checkout: { fullfilment: null, address: null, notes: null },
        selectedProduct: undefined,
        selectedProducts: [],
        hasNoOptions: true,
        productCategories: [],
        categorySelect: "",
        searchProduct: {},
        pagerSize: 1,
        activePageNum: 1,

        headers: [
          { text: "Images", value: "image", sortable: false, width: "100px" },
          { text: "Name", value: "name", sortable: false },
          { text: "Price", value: "price", sortable: false },
          { text: "", value: "action", sortable: false, align: "right" },
        ],
      };
    },

    computed: {
      products: function () {
        return this.$store.state.products.products;
      },
      totalItems: function () {
        return this.$store.state.products.totalItems;
      },
      reload: function () {
        return this.$store.state.products.loading;
      },
      pagination: function () {
        return this.$store.state.products.pagination;
      },

      cart: function () {
        return this.$store.state.connections.cart;
      },
      orderFor: function () {
        return this.$store.state.connections.orderFor;
      },
      customer: function () {
        return this.$store.state.connections.orderFor.name;
      },
      loading: function () {
        return this.$store.state.connections.loading;
      },
      activeShop: function () {
        return this.$store.state.shops.activeShop;
      },
    },

    watch: {
      cart: function (newVal, oldVal) {
        if (
          newVal.items.length !== oldVal.items.length &&
          oldVal.items != null
        ) {
          this.$nextTick(function () {
            this.$store.dispatch("connections/fetchCart");
          });
        }
      },
      checkoutDialog: function () {
        requestAnimationFrame(() => {
          this.$refs.button.$el.focus();
        });
      },
    },

    created: async function () {
      this.$store.dispatch("connections/fetchCart");
      this.$store.dispatch(
        "connections/fetchShopForOrg",
        +this.$props.connectionId
      );
      this.searchProduct = debounce(() => {
        if (this.searchVal == null) {
          this.searchVal = "";
          this.$store.dispatch("products/getProductsToOrder", {
            body: { name: this.searchVal, tags: [{ ...this.categorySelect }] },
            connectionId: +this.$props.connectionId,
          });
        } else {
          this.$store.dispatch("products/getProductsToOrder", {
            body: { name: this.searchVal },
            connectionId: +this.$props.connectionId,
          });
        }
      }, 500);

      this.productCategories = await this.$store.dispatch(
        "products/getProductsGrouped",
        { page: 1, pagesize: 500 }
      );
      this.categorySelect = this.productCategories[0];
      this.filterByCategory(this.categorySelect);
    },

    methods: {
      async filterByCategory(item) {
        if (item) {
          this.categorySelect = item;
          this.searchVal = "";
        }

        await this.$store.dispatch("products/getProductsToOrder", {
          body: { name: this.searchVal, tags: [{ ...this.categorySelect }] },
          connectionId: +this.$props.connectionId,
        });
        this.getPageNumbers(this.totalItems);
      },
      getCheapestOption(product) {
        let cheapestOption = -1;

        if (product.optionSets && product.optionSets.length) {
          product.optionSets.forEach((optionSet) => {
            if (optionSet.options && optionSet.options.length) {
              optionSet.options.forEach((option) => {
                if (cheapestOption == -1 || option.price <= cheapestOption) {
                  let optionPrice = option.price == null ? 0 : option.price;
                  cheapestOption = optionPrice;
                }
              });
            }
          });
        }

        const productPrice =
          cheapestOption == -1 ? product.price : product.price + cheapestOption;
        return cheapestOption == -1
          ? this.formatCurrency(`${productPrice}`)
          : `From ${this.formatCurrency(productPrice)}`;
      },
      getPageNumbers(total) {
        this.pagerSize = Math.ceil(total / this.pagination.itemsPerPage);
      },
      async loadMore(getPage) {
        if (this.activePageNum != getPage) {
          this.$store.dispatch("products/paginate", {
            ...this.pagination,
            page: getPage,
          });
          this.activePageNum = getPage;

          await this.$store.dispatch("products/getProductsToOrder", {
            body: { name: this.searchVal, tags: [{ ...this.categorySelect }] },
            connectionId: +this.$props.connectionId,
          });
        }
      },
      formatCurrency(item) {
        return Utils.formatText(item, Utils.TextType.CURRENCY);
      },
      formatOptionCurrency(productPrice, optionPrice) {
        const price = this.hasNoOptions
          ? productPrice
          : productPrice + optionPrice;

        return Utils.formatText(price, Utils.TextType.CURRENCY);
      },
      formatDateTime(item) {
        return Utils.formatText(item, Utils.TextType.DATE_TIME);
      },
      checkLabels(product) {
        let label = product.tags.filter((tag) => {
          return tag.tagType.name === "label";
        });

        return label.length > 0 ? label[0].name : "";
      },
      addToCartDialog(product) {
        this.addDialog = true;
        this.selectedProduct = product;
        // clear options
        this.optionsToBeAdded = [];

        if (
          product.optionSets &&
          product.optionSets.length &&
          product.optionSets[0].options &&
          product.optionSets[0].options.length
        ) {
          this.hasNoOptions = false;

          // loop through each option
          product.optionSets[0].options.forEach((option) => {
            let labels = product.tags.filter((tag) => {
              return tag.tagType.name === "label";
            });

            // name & price
            let productObj = {
              productName: product.name,
              productShortDescription: product.shortDescription,
              productDescription: product.description,
              productId: product.id,
              optionIds: [option.id],
              image: product.images.length ? product.images[0].thumbnail : null,
              name: option.name,
              price: option.price,
              available: product.available,
              labels: labels[0] ? labels[0].name : "",
            };

            this.optionsToBeAdded.push(productObj);

            // order by price
            this.optionsToBeAdded.sort((a, b) => {
              return a.price - b.price;
            });
          });
        } else {
          this.hasNoOptions = true;

          let labels = product.tags.filter((tag) => {
            return tag.tagType.name === "label";
          });

          // name & price
          let productObj = {
            productName: product.name,
            productShortDescription: product.shortDescription,
            productDescription: product.description,
            productId: product.id,
            image: product.images.length ? product.images[0].thumbnail : null,
            name: product.name,
            price: product.price,
            available: product.available,
            labels: labels[0] ? labels[0].name : "",
          };

          this.optionsToBeAdded.push(productObj);
        }
      },
      addProduct(item) {
        this.$store.dispatch("connections/addToCart", item);
      },
      cancelAdding() {
        // clear selection
        this.selectedProducts = [];
        this.addDialog = false;
      },
      addProducts() {
        this.selectedProducts.forEach((item) => {
          this.$store.dispatch("connections/addToCart", item);
        });

        // clear selection
        this.selectedProducts = [];
        this.addDialog = false;
      },
      selectProductsToAdd(item) {
        this.selectedProducts.push(item);
      },
      updateProduct(item) {
        let productObj = null;

        if (item.options != null) {
          productObj = {
            itemHash: item.itemHash,
            productId: item.detail.id,
            optionIds: [item.options[0].id],
            quantity: item.quantity,
          };
        } else {
          productObj = {
            itemHash: item.itemHash,
            productId: item.detail.id,
            optionIds: null,
            quantity: item.quantity,
          };
        }

        if (item.quantity === 0) {
          this.$store.dispatch("connections/removeFromCart", item.itemHash);
        } else {
          this.$store.dispatch("connections/updateCart", productObj);
        }
      },
      removeItemFromCart(item) {
        this.$store.dispatch("connections/removeFromCart", item.itemHash);
        if (this.cart.items.length < 2) {
          // close dialog as it was the last one removed before update
          this.cartDialog = false;
        }
      },
      getAddress(addressId) {
        let address = this.orderFor.addresses.filter(function (thisAddress) {
          return thisAddress.id == addressId;
        });
        return address[0].name;
      },
      checkoutCart() {
        let orderPayload = {
          supplierId: this.$store.state.shops.activeShop.id,
          organisationId: this.orderFor.id,
          amount: this.cart.cartTotal,
          deliveryType: this.checkout.fullfilment,
          address: {
            id: this.checkout.address,
          },
          note: this.checkout.notes,
          orderSourceChannel: {
            id: 7,
            name: "Supplier Dashboard",
            description: `Order on behalf of ${this.orderFor.name}`,
          },
        };

        this.$store.dispatch("connections/placeOrder", orderPayload);
        this.checkoutDialog = false;
      },
    },
  });
</script>

<style scoped>
  .product-preview {
    margin: 0 auto 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .product-preview .header {
    background-color: #c3c5c9;
    font-size: 12px;
    color: #33404d;
    text-align: center;
  }
  .product-preview .header .product-title {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 5px;
    line-height: 1;
  }
  .product-preview .header .sub-title {
    font-size: 12px;
    font-weight: 600;
    padding: 0 0 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .product-preview .image {
    position: relative;
    background-color: #fff;
    overflow: hidden;
  }

  .product-preview .image img {
    width: 100%;
    padding: 2px;
    height: auto;
    margin: auto;
  }

  .product-preview .image .tags {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    text-transform: uppercase;
    font-weight: bold;
    background-color: rgba(37, 138, 255, 0.9);
    color: #fff;
    font-size: 14px;
    padding: 4px;
  }

  .product-preview .footer {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    background-color: #697785;
  }

  .v-list-item.active {
    background-color: var(--v-accent-base) !important;
    border-color: var(--v-accent-base) !important;
  }

  .v-list-item.active .v-list-item__title {
    color: #fff !important;
  }

  .pagination {
    display: flex;
    list-style-type: none;
    border-radius: 5px;
  }

  .page-link {
    position: relative;
    display: block;
    padding: 15px;
    margin-left: -2;
    line-height: 1;
    color: #000;
    background-color: #fff;
    border: 1px solid #dddddd;
    text-decoration: none;
  }

  .page-link:hover {
    color: #fff;
    text-decoration: none;
    background-color: var(--v-accent-base);
    border-color: var(--v-accent-base);
  }

  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  /* Opinionated: add "hand" cursor to non-disabled .page-link elements */
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .page-item:last-child .page-link {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: var(--v-accent-base);
    border-color: #dddddd;
  }

  .page-item.disabled .page-link {
    color: #ccc;
    pointer-events: none;
    /* Opinionated: remove the "hand" cursor set previously for .page-linkv*/
    cursor: auto;
    background-color: #eee;
    border-color: #dddddd;
  }
</style>
